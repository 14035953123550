var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.componentId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": ["anti-shift", _vm.componentId],
            scrollable: "",
          },
          on: {
            hidden: function ($event) {
              return _vm.$emit("update:show", false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      staticStyle: { "margin-right": "auto" },
                      attrs: {
                        disabled:
                          !_vm.exists || _vm.waiting || _vm.user.ldapLogin,
                        size: "sm",
                        variant: "secondary",
                      },
                      on: { click: _vm.sendMail },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("button.reset_password")) + " "
                      ),
                    ]
                  ),
                  _vm.canEdit()
                    ? _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.ok()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.ok")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _vm.isAvatarBannerVisible
            ? _c("AvatarBanner", {
                attrs: {
                  readOnly: _vm.isAvatarBannerReadOnly,
                  baseAvatarIcon: ["fad", "user-tie"],
                },
                on: { status: _vm.avatarBannerStatus },
                model: {
                  value: _vm.avatarBanner,
                  callback: function ($$v) {
                    _vm.avatarBanner = $$v
                  },
                  expression: "avatarBanner",
                },
              })
            : _vm._e(),
          _c(
            "b-alert",
            {
              attrs: {
                variant: _vm.alertError ? "danger" : "success",
                dismissible: "",
                show: _vm.showError,
              },
              on: { dismissed: _vm.dismissAlert },
            },
            [
              _c("font-awesome-icon", {
                attrs: {
                  icon: _vm.alertError
                    ? ["fas", "triangle-exclamation"]
                    : ["far", "check"],
                },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
              _c(
                "ul",
                { staticClass: "mb-0", attrs: { show: _vm.showErrorDetail } },
                [
                  _vm._l(_vm.alertMsgDetails, function (item, index) {
                    return [_c("li", { key: index }, [_vm._v(_vm._s(item))])]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm.isEmailVisible
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t("user.field.email"),
                    "label-for": "email",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "email",
                          type: "text",
                          "data-vv-as": _vm.$t("user.field.email"),
                          "data-vv-name": "user.email",
                          "data-vv-delay": "500",
                          readonly: true,
                        },
                        model: {
                          value: _vm.user.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "email", $$v)
                          },
                          expression: "user.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-invalid-feedback",
                    {
                      staticClass: "alert-danger form-field-alert",
                      class: { "d-block": _vm.showEmailError },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "circle-exclamation"] },
                      }),
                      _vm._v(
                        "  " + _vm._s(_vm.errors.first("user.email")) + " "
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isFirstNameVisible
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t("user.field.firstName"),
                    "label-for": "firstName",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "firstName",
                          type: "text",
                          "data-vv-as": _vm.$t("user.field.firstName"),
                          "data-vv-name": "user.firstName",
                          "data-vv-delay": "500",
                          readonly: _vm.isFirstNameReadOnly,
                        },
                        model: {
                          value: _vm.user.firstName,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "firstName", $$v)
                          },
                          expression: "user.firstName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-invalid-feedback",
                    {
                      staticClass: "alert-danger form-field-alert",
                      class: { "d-block": _vm.showFistNameError },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "circle-exclamation"] },
                      }),
                      _vm._v(
                        "  " + _vm._s(_vm.errors.first("user.firstName")) + " "
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isLastNameVisible
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t("user.field.lastName"),
                    "label-for": "lastName",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "lastName",
                          type: "text",
                          "data-vv-as": _vm.$t("user.field.lastName"),
                          "data-vv-name": "user.lastName",
                          "data-vv-delay": "500",
                          readonly: _vm.isLastNameReadOnly,
                        },
                        model: {
                          value: _vm.user.lastName,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "lastName", $$v)
                          },
                          expression: "user.lastName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-invalid-feedback",
                    {
                      staticClass: "alert-danger form-field-alert",
                      class: { "d-block": _vm.showLastNameError },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "circle-exclamation"] },
                      }),
                      _vm._v(
                        "  " + _vm._s(_vm.errors.first("user.lastName")) + " "
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isNickNameVisible
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t("user.field.nickName"),
                    "label-for": "nickName",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "nickName",
                          type: "text",
                          readonly: _vm.isNickNameReadOnly,
                        },
                        model: {
                          value: _vm.user.nickName,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "nickName", $$v)
                          },
                          expression: "user.nickName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isMobileVisible
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t("user.field.mobile"),
                    "label-for": "mobile",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "mobile",
                          type: "text",
                          readonly: _vm.isMobileReadOnly,
                        },
                        model: {
                          value: _vm.user.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "mobile", $$v)
                          },
                          expression: "user.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("PasswordModal", {
        attrs: {
          register: false,
          tokenId: _vm.tokenId,
          show: _vm.passwordShow,
        },
        on: {
          "update:show": function ($event) {
            _vm.passwordShow = $event
          },
          success: _vm.passwordSuccess,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }